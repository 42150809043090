<template>
	<v-container v-if="reference['id'] == -1">{{ $t("reference.loading_all") }}</v-container>
	<v-container fluid v-else>
		<v-container>
			<v-row dense>
				<v-col class="d-flex flex-column" cols="12">
					<v-card class="flex d-flex flex-column" outlined tile elevation="24">
						<v-row dense class="incenter seda">
							<v-col>
								<v-card-title class="headline">{{ reference['ref_name'] }}</v-card-title>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col class="incenter">
								<v-avatar class="profile" size="120" tile>
									<v-img contain :src="get_img_url(reference['link'])"></v-img>
								</v-avatar>
							</v-col>
						</v-row>
						
											<v-card-title class="incenter">{{ reference["ref_project_name"] }}</v-card-title>
									
												<v-card-text>
													{{ $t('reference.product_type') }}: {{ reference['ref_product_type'] }} <br>
													{{ $t('reference.field') }}: {{ reference['ref_field'] }}
													
												</v-card-text>
											<v-card-text class="gugi" v-html="reference['ref_description']"></v-card-text>
									
                       
						<!-- </div> -->
					</v-card>

						<!-- <div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="headline">{{ product.product }}</v-card-title>
								<v-card-subtitle>{{ product.description_short }}</v-card-subtitle>
							</div>
							<v-avatar class="ma-3" size="150" tile>
								<v-img contain :aspect-ratio="16 / 9" :src="product.img"></v-img>
							</v-avatar>
						</div> -->
				</v-col>
			</v-row>
        </v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	data: () => ({
		reference: {
            id: -1
        },
	}),
	methods: {
        get_img_url(img) {
			return '/pictures/pics/reference/' + img
		},
	},
	mounted() {
		ApiService.get("/reference/" + this.$route.params.id).then((response) => {
            this.reference = response.data
		}).catch(() => {})
	}
};
</script>

<style scoped>
.gugi {
	text-align: justify;
	word-break: normal;
}
.v-card {
	margin-bottom: 5px;
}
.v-tab {
	text-transform: none;
	font-size: 0.75rem;
}

.v-card__title {
	padding-top: 0px;
}

.container {
	padding-top: 0px;
}
.smaller {
	font-size: 0.65em;
}
.incenter {
	text-align: center;
}
.headline {
	font-size: 1.2rem !important;
	display: block;
	padding: 0;
}
.seda {
	background-color: rgb(245, 245, 245);
	margin: 0;
}
</style>
